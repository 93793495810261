<template>
  <div class="pwdChange">
    <van-form>
      <van-field :rules="[{ required: true, message: '请输入原手机号' }]" v-model="dataForm.oldMobile" placeholder="请输入原手机号" />
      <van-field :rules="[{ required: true, message: '请输入新手机号' }]" v-model="dataForm.newMobile" placeholder="请输入新手机号" />
      <van-field :rules="[{ required: true, message: '请输入验证码' }]" v-model="dataForm.code" placeholder="请输入验证码" >
        <template #button>
          <van-button  @click="getCode" ><p :style="isCode ? 'color:#256EF8' : 'color: #ccc'">发送验证码{{isCode ? '' : num}}</p></van-button>
        </template>
      </van-field>
      <div style="position:relative;">
        <van-button class="btn" round type="info" @click="changePwd">验证</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataForm: {
        oldMobile: '',
        newMobile: '',
        code: '',
      },
      num: 30,
      isCode: true,
      timer: null
    }
  },
  methods: {
    changePwd () {
      if (this.dataForm.oldMobile.trim() === '') {return this.$toast.fail('请输入旧手机号')}
      if (this.dataForm.newMobile.trim() === '') {return this.$toast.fail('请输入新手机号')}
      if (this.dataForm.code.trim() === '') {return this.$toast.fail('请输入验证码')}

      this.$http({
        url: this.$http.adornUrl('/wxapp/user/updateMobile'),
        method: 'post',
        params: this.$http.adornParams({
          newMobile: this.dataForm.newMobile,
          oldMobile: this.dataForm.oldMobile,
          code: this.dataForm.code
        },false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success('修改成功')
          this.$router.go(-1)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getCode () {
      if (this.dataForm.newMobile.trim() === '') {return this.$toast.fail('请输入手机号')}
      if (this.isCode) {
        this.isCode = false
        this.$http({
          url: this.$http.adornUrl('/general/verify/sendVerifyCode'),
          method: 'post',
          params: this.$http.adornParams({
            mobile: this.dataForm.newMobile
          },false)
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success('发送成功')
          } else {
            this.$toast.fail(data.msg)
          }
        })
        this.timer = setInterval(() => {
          this.num--
          if (this.num === 0) {
            this.isCode = true
            clearInterval(this.timer)
            this.num = 30
          }
        }, 1000)
      }
    }
  }
}
</script>
<style scoped>
.pwdChange >>> .van-field__control {
  text-align: left !important;
}
.pwdChange >>> .van-button--default {
  border: none !important;
}
.btn {
  width: 600px;
  position: absolute;
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
}
</style>
